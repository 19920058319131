import React, { useEffect } from "react";
import styled from "styled-components";
import { AppLogo } from "../../components/ui";
import { BasicLayout } from "../../layouts/BasicLayout";
import theme from "../../util/theme";
import { mobileSize } from "../../util/variables";
import { navigate } from "gatsby";
import { signInWithApple, signInWithGoogle } from "../../services/firebase-sso";
import {
  track_apple_createAccount_start,
  track_google_createAccount_start,
} from "../../services/mixpanel/mixpanel";
import {
  web_acq_create_account_attempt,
  web_acq_create_account_failure,
  web_acq_create_account_success,
  web_acq_create_account_view,
} from "../../services/mixpanel/acquisition-events";
import { triggerCustomEvent } from "../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../util/types";
import { LogInForm } from "../../components/ui/LogInForm";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { WEB_PAID_TRIAL_FLOW } from "../get-started";

const APPLE = "apple";
const GOOGLE = "google";

const getRedirectLink = (onboardingLandingPage: string) => {
  return onboardingLandingPage === WEB_PAID_TRIAL_FLOW ? "/try" : "/special-offer";
};

export default () => {
  const { onboardingLandingPage } = useOnboardingContext();
  const REDIRECT_LINK = getRedirectLink(onboardingLandingPage);
  useEffect(() => {
    web_acq_create_account_view();
  }, []);

  const handleGoogleLogin = async () => {
    web_acq_create_account_attempt(GOOGLE);
    track_google_createAccount_start();
    const res = await signInWithGoogle();

    if (typeof res === "boolean") {
      web_acq_create_account_failure(GOOGLE);
    } else {
      triggerCustomEvent(GTM_CUSTOM_EVENT.COMPLETE_REGISTRATION);
      web_acq_create_account_success(GOOGLE);
      localStorage.setItem("email", res.user.email ?? "");
      localStorage.setItem("uid", res.user.uid);
      navigate(REDIRECT_LINK, {
        state: {
          uid: res.user.uid,
          email: res.user.email,
        },
      });
    }
  };

  const handleAppleLogin = async () => {
    web_acq_create_account_attempt(APPLE);
    track_apple_createAccount_start();
    const res = await signInWithApple();
    if (typeof res === "boolean") {
      web_acq_create_account_failure(APPLE);
    } else {
      triggerCustomEvent(GTM_CUSTOM_EVENT.COMPLETE_REGISTRATION);
      web_acq_create_account_success(APPLE);
      localStorage.setItem("email", res.user.email ?? "");
      localStorage.setItem("uid", res.user.uid);
      navigate(REDIRECT_LINK, {
        state: {
          uid: res.user.uid,
          email: res.user.email,
        },
      });
    }
  }; 

  const handleEmailLogin = () => {
    navigate(`/create-account/email`);
  };

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <LogoContainer>
          <AppLogo size="medium" />
        </LogoContainer>
        <Title>Unlock the Future of Learning</Title>
        <LogInForm
          title="Create a Free Account"
          handleEmailLogin={handleEmailLogin}
          handleAppleLogin={handleAppleLogin}
          handleGoogleLogin={handleGoogleLogin}
        />
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0;
  }
`;

const Title = styled.h1`
  margin-bottom: 24px;
  font-size: 35px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-align: center;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-bottom: 16px;
    font-size: 24px;
    /* font-weight: 700; */
  }
`;
